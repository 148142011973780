<template>
	<div>
		<Header></Header>
		
		<div class="content-area" >
			<div class="standard-page-header">
				Northern Shootout
			</div>
            <div class="frontend-content-wrapper">
				<div class="section-spacing">
                    <div class="row">
                        <div class="col-md-4">
                            <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/Northern Shootout Logo.png" width="100%" alt="Northern Shootout">
                        </div>
                        <div class="col-md-8">
                            <div id="map"></div>
                        </div>
                    </div>
                    
                    <div style="margin-top: 20px;">
                        <b-tabs >
                            <b-tab-item label="Northern Shootout" v-if="shootout_main">
                                
                                <PageContentBox :content="parseMainContent(shootout_main.content)"></PageContentBox>
                            </b-tab-item>
                            <b-tab-item label="Previous Tournament Results">
                                <div class="panel">
                                    <div class="panel-heading" v-on:click="first_decade = !first_decade">2023-2018<i :class="['fas', { 'fa-plus': first_decade === false }, { 'fa-minus': first_decade === true }]" style="float: right;"></i></div>
                                    <div class="panel-content" v-if="first_decade">
                                        <b-tabs>
                                            <b-tab-item label="2023">
                                                <PageContentBox :content="grabTournamentResults('2023')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2019">
                                                <PageContentBox :content="grabTournamentResults('2019')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2018">
                                                <PageContentBox :content="grabTournamentResults('2018')"></PageContentBox>
                                            </b-tab-item>
                                            
                                        </b-tabs>
                                    </div>
                                </div>
                            </b-tab-item>
                        </b-tabs>
                    </div>
				</div>
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import PageContentBox from '../frontend/PageContentBox.vue'
export default {
    metaInfo() {
        return { 
            title: "Northern Shootout - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Check out one of the most talent-filled tournaments of the summer! Located in Orillia, Ontario.' },
                { property: 'og:title', content: "Northern Shootout - Slo-Pitch National"},
                { property: 'og:url', content:  'https://www.slo-pitch.com/northern-shootout' },
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/northernshootout.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
            page: null,
            shootout_main: null,
            previous_results: null,
            first_decade: false,
            second_decade: false,
            third_decade: false,
            nationals_hosts: null,
            brampton: false,
            winnipeg: false,
            edmonton: false,
            leduc: false,
            kelowna: false,
		};
	},
	components: {
		Header,
		Footer,
        Form,
        PageContentBox
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this

   

        // The location of Uluru
        
        const orillia = { lat: 44.6082, lng: -79.4197 }
        const center = { lat: 44.6082, lng: -79.4197 }
        const leduc = { lat: 53.2723418, lng: -113.5847182 }
        const edmonton = { lat: 53.5557121, lng: -113.6331451 }
        
        const winnipeg = { lat: 49.8538008, lng: -97.2224356 }
        
        const brampton = { lat: 43.7249125, lng:-79.8297545 }
        // The map, centered at Uluru
        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 9,
            center: center,
        });
 

        axios.get('/api/pages/northern-shootout').then(results => {
            if(results.data.success === true) {
                vm.previous_results = results.data.years
                vm.shootout_main = results.data.shootout_main
            }
        })
     
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
	},
	methods: {
        parseMainContent: function(content) {
            return JSON.parse(content)
        },
        grabNationalsHost: function(city) {
            var vm = this
            var tourney = _.find(vm.nationals_hosts, function(result) {
                return result.slug === 'nationals-host-' + city
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)

                return content
            }
            
            // var content = JSON.parse(tourney)
            return []
            // return tourney
        },
        grabTournamentResults: function(year) {
            var vm = this
            var tourney = _.find(vm.previous_results, function(result) {
                return result.slug === 'ns-results-' + year
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)

                return content
            }
            
            // var content = JSON.parse(tourney)
            return []
            // return tourney
        },
		openPanel:function(panel) {
			if(panel.switched === "false") {
				panel.switched = "true"
			} else {
				panel.switched = "false"
			}
		},
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {
				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
