<template>
    <div>
        <span v-if="listOrBox === 'box'">
            <div class="row" v-for="(chunk,key) in chunk(tournaments, 3)" :key="key" >
                <div class="col-md-4" v-for="list in chunk" :key="list.id">
                    <div class="sm-margin">
                    <LeagueTournamentPreviewCard
                        :event="list"
                        :bottomHalf="true"
                        :eventType="'tournament'"
                        :posterUploadAbility="false"
                        :advertiseIt="true">
                    </LeagueTournamentPreviewCard>  
                    </div>
                </div>
            </div>
        </span>
        <span v-if="listOrBox === 'list'">
            <div class="card" v-for="event in tournaments" :key="event.id">
                <div class="row" >
                    <div class="col-md-2">
                        <div v-if="event.poster_image_url">
                            <img :src="event.poster_image_url" v-if="event.poster_image_url" alt="Poster Image">
                        </div>
                        <span v-if="event.status === 'provincials'">
                            <img src="https://cdn.blacktiecollab.com/slo-pitch.com/genericprovies.png" v-if="!event.poster_image_url" alt="Poster Image">
                        </span>
                        <span v-else>
                            <img src="https://slo-pitch.com/uploads/facebook/sanctionedtournament.png" v-if="!event.poster_image_url" alt="Poster Image">
                        </span>
                    </div>
                    <div class="col-md-5">
                        <div class="padding">
                            <div class="custom-event-box" style="text-align: left;">
                                <div class="custom-tournament-name" style="height: auto !important; padding-top: 10px;">
                                    {{ event.name }}
                                </div>
                                <div class="starts-on" style="text-align: left;">
                                    <b>{{ moment(event.event_start_date).format('MMMM D') }} - {{ moment(event.event_end_date).format('MMMM D')}}</b>
                                </div>
                                <div>
                                    <pre :class="['custom-tournament-description', { viewmore: fullDescript === true }]" v-html="event.description" style="padding: 10px 0;">
                                    
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="custom-tournament-divisions" style="padding: 10px;">
                            <div v-if="event.divisions.length > 0">
                            
                                <div class="row" v-if="filterDivision(event.divisions, 'mens').length > 0">
                                    <div class="col-md-3">
                                        <div class="custom-tournament-division-header">
                                            Mens
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <b-tag
                                            type="is-danger"
                                            :key="key"
                                            v-for="(division, key) in filterDivision(event.divisions, 'mens')">
                                            {{ division.rank_id }}
                                        </b-tag>
                                    </div>
                                </div>
                                <div class="row margin-top" v-if="filterDivision(event.divisions, 'ladies').length > 0">
                                    <div class="col-md-3">
                                        <div class="custom-tournament-division-header">
                                            Ladies
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <b-tag
                                            type="is-danger"
                                            :key="key"
                                            v-for="(division, key) in filterDivision(event.divisions, 'ladies')">
                                            {{ division.rank_id }}
                                        </b-tag>
                                    </div>
                                </div>
                                <div class="row margin-top" v-if="filterDivision(event.divisions, 'coed').length > 0">
                                    <div class="col-md-3">
                                        <div class="custom-tournament-division-header">
                                            Coed
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <b-tag
                                            type="is-danger"
                                            :key="key"
                                            v-for="(division, key) in filterDivision(event.divisions, 'coed')">
                                            {{ division.rank_id }}
                                        </b-tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="custom-tournament-entry-fees">
                            <div class="entry-fee-text">
                                Entry Fee:
                            </div>
                            <div class="entry-fee-team">
                                <i class="fas fa-users"></i> ${{ event.team_registration_fee.toFixed(2) }}
                            </div>
                            <div class="entry-fee-team" v-if="event.player_registration_fee">
                                <i class="fas fa-user"></i> ${{ event.player_registration_fee.toFixed(2) }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <a role="button" style="width: 100%; text-align: center;" :href="'/'+ eventType +'/' + event.slug" class="admin-button">
                            View Event Details
                            <span>Registration ends {{ moment(event.registration_end_date).format('MMMM D') }}!</span>
                        </a>
                    </div>
                </div>
            </div>
        </span>

        
    </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import LeagueTournamentPreviewCard from '../components/LeagueTournamentPreviewCard.vue'
import PaymentProcessor from '../components/PaymentProcessor.vue'
import moment from 'moment'
export default {
	data() {
		return {
            en: true,
            upcomingTournaments: [],
            upcomingTournamentsStep: 0,
            tournamentSelected: null,
            step: 0,
            authUser: null,
            selectedTeam: null,
            eventType: 'tournament',
            selectedRosterPlayers: [],
            registerForTournament: {
                team: null,
                gender: null,
                division: null
            },
            teams: null,
            team_roster: [],
            region_rate: null,
            selectedInsuranceOption: null,
            paymentSuccess: false,
            triggerFinalize: false,
            fullDescript: true
		};
	},
	components: {
        // add multiple teams to the divisions, limit at one team per mens coed and ladies
        PaymentProcessor,
        LeagueTournamentPreviewCard
    },
    watch: {
        selectedTeam: function($event) {
            var vm = this
            axios.get('/api/rosters/grab-roster/' + $event.team_slug).then(results => {
                if(results.data.success === true) {
                    vm.team_roster = results.data.team.core_roster
                }
            })
        },
        paymentSuccess: async function($event) {
            
            // now trigger automatically Submit & Receive Your Certificate

            await this.finalizeAndRegister()
        }
    },
	mounted: function() {
        var vm = this

        axios.get('/auth/check').then(results => {  
            var location = null
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                
                if(sessionStorage.getItem('user_location')) {
                    location = sessionStorage.getItem('user_location')
                }
            }
        })

        axios.get('/api/users/teams').then(results => {
            if(results.data.type === 'success') {
                vm.teams = results.data.data.teams
            }
        })
    },
    props: ['carouselSize','tournaments', 'listOrBox'],
	computed: {
        totalDivisions: function() {
            var vm = this
            var mens = vm.filterDivision(vm.tournamentSelected.divisions, 'mens')
            var ladies = vm.filterDivision(vm.tournamentSelected.divisions, 'ladies')
            var coed = vm.filterDivision(vm.tournamentSelected.divisions, 'coed')

            var count = 0
            if(mens.length > 0) {
                count = count + 1
            } 
            if(ladies.length > 0) {
                count = count + 1
            }
            if(coed.length > 0) {
                count = count + 1
            }

            return {
                mens: mens,
                ladies: ladies,
                coed: coed,
                count: count
            }
        },
        spnRegistration: function() {
            // grab the registrations of insurance from the team
            // cross reference the division
            var vm = this

            var team = vm.selectedTeam

            var selected_gender = vm.registerForTournament.gender

            
            if(team) {
                
                var insurance = team.insurance
                
                var find_insurance = _.find(insurance, function(paper) {
                    if(paper.gender === selected_gender) {
                        return true
                    }
                })

                // check validity of the insurance that was found (must be 2020, must not be single use - if it's single use it means it's been used already (because they signed up and it was single use))
                // if a single use one is found, it must be upgraded
                // when updated it will remove single use from the row

                if(find_insurance) {
                    vm.triggerFinalize = true
                    return true
                }
                vm.triggerFinalize = false
                return false
            } else {
                vm.triggerFinalize = false
                return false
            }
        },
        regionRateOptions: function() {
            var vm = this

            var array = []
            if(vm.region_rate) {
                if(vm.region_rate.rate.single_use_tournament_team !== null) {
                    array.push({
                        name: 'Play One Time',
                        price: vm.region_rate.rate.single_use_tournament_team,
                        price_id: vm.region_rate.rate.single_use_tournament_team_price_id,
                        id: 8
                    })
                }
                if(vm.region_rate.rate.un_insured_tournament_team !== null) {
                    array.push({
                        name: 'Uninsured Tournament Team',
                        price: vm.region_rate.rate.un_insured_tournament_team,
                        price_id: vm.region_rate.rate.un_insured_tournament_team_price_id,
                        id: 7
                    })
                }
                if(vm.region_rate.rate.fully_insured_tournament_team !== null) {
                    array.push({
                        name: 'Fully Insured Tournament Team',
                        price: vm.region_rate.rate.fully_insured_tournament_team,
                        price_id: vm.region_rate.rate.fully_insured_tournament_team_price_id,
                        id: 6
                    })
                }
            }

            return array
        }
	},
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
        filterRoster: function(roster) {
            // cannot have ladies in mens, cannot have mens in ladies

            var vm = this

            var roster = vm.team_roster

            if(vm.registerForTournament.gender === 'mens') {
                roster = _.filter(roster, function(player) {
                    if(player.user.gender === 'Male') {
                        return true
                    }
                })
            }
            if(vm.registerForTournament.gender === 'ladies') {
                roster = _.filter(roster, function(player) {
                    if(player.user.gender === 'Female') {
                        return true
                    }
                })
            }

            return roster
        },
		// submitPaymentAttempt: function() {
        //     var vm = this 
        //     vm.paymentSuccess = true
        //     vm.$emit('payment:successful', true)
        // }
        emitRegisterNow: function(list) {
            var vm = this
            vm.tournamentSelected = list
            
            
			axios.post('/api/tournaments/region-rate', {
				region: vm.tournamentSelected.region_id
			}).then(results => {
				if(results.data.success === true) {
                    
                    vm.region_rate = results.data.region_rate
                    vm.emitUnloggedRegister(list.id)
                    vm.$emit('register:now', {
                        tournamentSelected: list,
                        region_rate: results.data.region_rate
                    })
				}
            })
        },
        paymentSuccessSwitch: function($event) {
            var vm = this
            vm.paymentSuccess = $event
            this.triggerFinalize = true
        },
        finalizeAndRegister: function() {
            var vm = this
            // verify that players are selected, so is team, etc
            axios.post('/api/tournaments/register-for-tournament', {
                team_id: vm.selectedTeam.id,
                roster: vm.selectedRosterPlayers,
                gender_id: vm.registerForTournament.gender,
                division_id: vm.registerForTournament.division.id,
                tournament_id: vm.tournamentSelected.id,
                order_id: vm.paymentSuccess.id
            }).then(results => {
                // TO DO
            })
        },
        selectInsuranceOption: function(option) {
            var vm = this
            vm.selectedInsuranceOption = option

            
        },
        emitUnloggedRegister: function(id) {
            // create axios to add a view click
            
            axios.post('/api/tournaments/track-click', {
                tournament_id: id
            }).then(results => {
                if(results.data.success === true) {
                    
                }
            })
        },
        chunk: function(events) {
            return _.chunk(events, 3)
        }
	}
}
</script>

<style scoped>
.card {
    background: #fff;
    border: 1px solid #ddd;
    margin: 10px;
}
</style>