<template>
<span>
  
    <div class="header">
        <div class="header-wrapper">
            <div class="logo-main">
                <a href="/"><img src="../../../images/header/SPNLogo2019-White.png" alt="Logo"></a>
            </div>
            
            <div class="language-bar">
                <ul>
                    <li>
                        <a href="https://www.facebook.com/SloPitchNational" role="button" target="_blank" class="lang"><i class="fab fa-facebook" role="presentation"></i></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/spnslopitch" role="button" target="_blank" class="lang"><i class="fab fa-twitter" role="presentation"></i></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/spnslopitch/" role="button" target="_blank" class="lang"><i class="fab fa-instagram" role="presentation"></i></a>
                    </li>
                    <li>
                        <a href="https://www.pinterest.com/spnslopitch/" role="button" target="_blank" class="lang"><i class="fab fa-pinterest" role="presentation"></i></a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCAH0gIUTWZSObWcY8EZ0xBA" role="button" target="_blank" class="lang"><i class="fab fa-youtube" role="presentation"></i></a>
                    </li>
     
                    <li>
                        <a href="tel:9058637666" role="button" class="lang"><i class="fas fa-phone" role="presentation"></i><span class="phone-number-data"> &nbsp;(905) 863-7666</span></a>
                    </li>
                    <li>
                        <a role="button" :class="['lang', { selected: en === true }]" v-on:click="selectLang(true)">EN</a>|<a role="button" :class="['lang', { selected: en === false }]" v-on:click="selectLang(false)">FR</a>
                    </li>
                    <li>
                        <a role="button" v-if="!authUser" href="/login" class="button login-central">{{ en === true ? 'Login to SPN Central' : 'Connectez-vous à SPN Central' }}</a>
                        <a role="button" v-if="authUser" href="/dashboard" class="button login-central">{{ en === true ? 'Go To SPN Central' : 'Accédez à SPN Central' }}</a>
                    </li>
                </ul>
            </div>
            <div class="second-bar">
                <ul>
                  
                    <li>
                        <a role="button" v-if="en === true" href="https://sajkpk7k4mr.typeform.com/to/oT2uY7AO" class="button login-central">{{ en === true ? 'Report an Injury/INCIDENT' : "Rapport D'accident" }}</a>
                        <a role="button" v-if="en === false" href="https://sajkpk7k4mr.typeform.com/to/uu8Bj7Tc" class="button login-central">{{ en === true ? 'Report an Injury/INCIDENT' : "Rapport D'accident" }}</a>
                  
                        
                    </li>
                    
                </ul>
            </div>

        </div>
    </div>
    <div class="provinces-section">
        <div class="province-wrapper">
            <div class="province-header">
                INDIVIDUALLY DISTINCTIVE &amp; COLLECTIVELY STRONG
            </div>
            <div class="provinces">
                <div class="province">
                    <a aria-label="British Columbia Crest" role="button" href="/memberships-by-region/bc" class="top-image-province">
                        <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/BCCrestwhite.png" width="100%" alt="BC Crest" v-if="en === true">
                        <img src="../../../images/header/crest/BCCrestwhiteFR.png" width="100%" alt="BC Crest" v-if="en === false">
                    </a>
                </div>
                <div class="province">
                    <a  aria-label="Alberta Crest" role="button" href="/memberships-by-region/ab" class="top-image-province">
                        <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/ABCrestwhite.png" width="100%" alt="AB Crest" v-if="en === true">
                        <img src="../../../images/header/crest/ABCrestwhiteFR.png" width="100%" alt="AB Crest" v-if="en === false">
                    </a>
                </div>
                <div class="province">
                    <a  aria-label="Saskatchewan Crest" role="button" href="/memberships-by-region/sk" class="top-image-province">
                        <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/SKCrestwhite.png" width="100%" v-if="en === true" alt="SK Crest">
                        <img src="../../../images/header/crest/SKCrestwhiteFR.png" width="100%" v-if="en === false" alt="SK Crest">
                    </a>
                </div>
                <div class="province">
                    <a  aria-label="Manitoba Crest" role="button" href="/memberships-by-region/mb" class="top-image-province">
                        <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/MBCrestwhite.png" width="100%" v-if="en === true" alt="MB Crest">
                        <img src="../../../images/header/crest/MBCrestwhiteFR.png" width="100%" v-if="en === false" alt="MB Crest">
                    </a>
                </div>
                <div class="province">
                    <a role="button" href="https://playslopitch.com/major-events/provincial-championships 
" target="_blank" class="top-image-province">
                        <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/ONCrestwhite.png" width="100%" v-if="en === true" alt="ON Crest">
                        <img src="../../../images/header/crest/ONCrestwhiteFR.png" width="100%" v-if="en === false" alt="ON Crest">
                    </a>
                </div>
                <div class="province">
                    <a  aria-label="Quebec Crest" href="/memberships-by-region/qc" class="top-image-province">
                        <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/QCCrestwhite.png" width="100%" v-if="en === true" alt="QC Crest">
                        <img src="../../../images/header/crest/QCCrestwhiteFR.png" width="100%" v-if="en === false" alt="QC Crest">
                    </a>
                </div>
                <div class="province">
                    <a  aria-label="Manitoba Crest" role="button" href="/memberships-by-region/mt" class="top-image-province">
                        <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/MTCrestwhite.png" width="100%" v-if="en === true" alt="MT Crest">
                        <img src="../../../images/header/crest/MTCrestwhiteFR.png" width="100%" v-if="en === false" alt="MT Crest">
                    </a>
                </div>
                <div class="province">
                    <a  aria-label="Newfoundland Crest" role="button" href="/memberships-by-region/nf" class="top-image-province">
                        <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/NLCrestwhite.png" v-if="en === true" width="100%" alt="NL Crest">
                        <img src="../../../images/header/crest/NLCrestwhiteFR.png" v-if="en === false" width="100%" alt="NL Crest ">
                    </a>
                </div>                
            </div>
        </div>
    </div>
    <div class="navigation">
        <ul class="main-menu" role="menu">
            <li :key="key" v-for="(item, key) in menu">
                <a v-if="item.hide_on_eng !== 1" role="button" v-on:click="selectMenu(item)">{{ en === true ? item.name : item.fr_name }}</a>
            </li>
        </ul>
    </div>
    <div class="mobile-navigation">
        <ul class="main-menu">
            <li>
                <a role="button" v-on:click="selectMobileMenu()">{{ mobileMenu ? 'Close' : 'Open' }} Main Menu</a>
            </li>
        </ul>
        <ul class="main-menu"  role="menu" v-if="mobileMenu">
            <li :key="key" v-for="(item, key) in menu">
                <a role="button" v-on:click="selectMenu(item)">{{ en === true ? item.name : item.fr_name }}</a>
            </li>
        </ul>
    </div>
    <div class="sub-navigation" v-if="submenu_filtered.length > 0">
        <ul class="main-menu">
            <li :key="key" v-for="(item, key) in submenu_filtered">
                <a role="button" :href="item.href">{{ en === true ? item.name : item.fr_name }}</a>
            </li>
        </ul>
    </div>
</span>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
    data() {
        return {
            en: true,
            popup: true,
            user_coordinates: {
                lat: null,
                long: null
            },
            searchComponentOpen: false,
            mobileMenu: false,
            adRandomizerTop: [
				{ url: 'https://mikensports.com/', image_url: '/uploads/ads/topmiken.png' },
				{ url: 'https://homerunsports.com/discount/SPN4YOU', image_url: '/uploads/ads/toprawlings.png' },
                { url: 'https://homerunsports.com/discount/SPN4YOU', image_url: '/uploads/ads/topsoftball.png' },
                { url: 'https://gameonmobile.com', image_url: '/uploads/ads/spn2.png' },
			],
            submenu_filtered: [],
            menu: [],
            submenus: [],
            authUser: null,
            notice: true,
            
        };
    },
    components: {
        
    },
    computed: {
        
    },
    mounted: function() {
        var vm = this
        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
				vm.authUser = results.data.user
			}
        })

        if(localStorage.getItem('popup')) {
            vm.popup = false
            
        }

        if(localStorage.getItem('notice')){
            vm.notice = false
        }
        
        const start = 9 * 60;
        const end = 17 * 60;
        const date = new Date(); 
        const now = date.getHours() * 60 + date.getMinutes();
        const day_of_week = date.getDay()
        if(start <= now && now <= end) {
            // console.log('Chat is hidden.')
        } else {
            $('#front-chat-container').hide()    
        }
        // if(day_of_week === 5) {
        //     $('#front-chat-container').hide()    
        // }
        if(day_of_week === 6) {
            $('#front-chat-container').hide()    
        }
        if(day_of_week === 0) {
            $('#front-chat-container').hide()    
        }
        
        vm.adRandomizerTop = _.shuffle(vm.adRandomizerTop)
        if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

    

        axios.get('/api/navigation/primary').then(results => {
            if(results.data) {
                vm.menu = results.data.main_menu
                vm.submenus = results.data.submenus
            }
        }).catch(err => {
            // console.log(err)
        })
    },
    
    methods: {
        geoLocateSuccess(position) {
            this.user_coordinates.lat = position.coords.latitude;
            this.user_coordinates.long = position.coords.longitude;
            var pos = JSON.stringify(position)
            sessionStorage.setItem('user_location', pos)
            // store this in sessionStorage as well, and then don't fire the popup if it's there
            axios.post('/auth/update-location', {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                location_accuracy: position.coords.accuracy
            }).then(results => {
                
            })
            // grab it to the auth user if there's one
        },
        geoLocateError() {
            var vm = this
        },
        closeNotice() {
            this.notice = false
            localStorage.setItem('notice', 'done')
        },
        selectMenu(item) {
            var vm = this
            
            if(item.has_submenu) {
                var filter = _.filter(vm.submenus, function(sub_item) {
                    return sub_item.submenu_id === item.slug
                })

                vm.submenu_filtered = filter
                vm.mobileMenu = false
                // console.log('item', filter)
            } else {
                if(item.href) {
                    // redirect
                    if(!this.en && item.href_fr) {
                        window.location.replace(item.href_fr)
                    } else {
                        window.location.replace(item.href)
                    }
                }
            }
        },
        selectSubMenu(item) {
            var vm = this
        
            this.$router.push({ path: '/content/' + item.href }).catch(err => { console.log(err) })   
        },
        selectLang: function(lang) {
            this.en = lang

            sessionStorage.setItem('lang', lang)

            this.$emit('update-lang', lang)
        },
        popupRemover: function() {
            localStorage.setItem('popup', 'done')
            this.popup = false
        },
        selectMobileMenu: function() {
            var vm = this
            vm.mobileMenu = !vm.mobileMenu
        },
       
    }
}
</script>

<style lang="scss">
    @import '../../../styles/Header.scss';
</style>
