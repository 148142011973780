<template>
	<div >
        
        <section class="modal-card-body frontend-replica" v-if="previewMode === true">
            <a style="float:right; margin-right: 10px;" class="button" v-on:click="previewMode = !previewMode">
                <span v-if="previewMode === false">Preview</span>
                <span v-if="previewMode === true">Edit</span>
            </a>
            <PageContentBox :content="contentAreas"></PageContentBox>
        </section>
        <section class="modal-card-body" v-if="previewMode === false">
            <a style="float:right; margin-right: 10px;" class="button" v-on:click="previewMode = !previewMode">
                <span v-if="previewMode === false">Preview</span>
                <span v-if="previewMode === true">Edit</span>
            </a>
            <div class="row">
                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-4">
                            <b-field label="Page Name">
                                <b-tooltip type="is-dark" label="This is the title for the page the user is on.">
                                    <b-input v-model="newPage.name"></b-input>
                                </b-tooltip>
                            </b-field>
                            <b-field label="French Page Name">
                                <b-tooltip type="is-dark" label="This is the title for the page the user is on.">
                                    <b-input v-model="newPage.fr_name"></b-input>
                                </b-tooltip>
                            </b-field>
                            <b-checkbox v-model="newPage.latest_news">Latest News</b-checkbox>
                        </div>
                        <div class="col-md-4">
                            <b-field label="Page Url">
                                <b-tooltip type="is-dark" label="This is the url for the page the user is on.">
                                    <div class="row">
                                        <div class="col-xs-3">
                                            <div style="margin-top: 8px;">/content/</div>
                                        </div>
                                        <div class="col-xs-6">
                                            <b-input v-model="newPage.slug"></b-input>
                                        </div>
                                    </div>
                                    <p class="note">This will be automatically assigned if left blank.</p>
                                </b-tooltip>
                            </b-field>
                        </div>
                        
                    </div>
                    
                    <b-tabs position="is-centered" expanded style="margin-top: 20px;">
                        <b-tab-item label="English Content" style='text-align: center;'>
                            
                            <div class="page-create-content-area" :key="key" v-for="(area, key) in contentAreas">
                                <div class="row" style="padding-bottom: 10px;">
                                    <div class="col-md-12">
                                        <button class="button is-small add-column" v-on:click="changeColumns(1, key, area)">One Column</button>
                                        <button class="button is-small add-column" v-on:click="changeColumns(2, key, area)">Two Columns</button>
                                        <button class="button is-small add-column"
                                        v-on:click="changeColumns(3, key, area)">Three Columns</button>
                                        <button class="button is-small add-column"
                                        v-on:click="changeColumns(4, key, area)">Four Columns</button>
                                        <button class="button is-small add-column"
                                        v-on:click="changeColumns(5, key, area)">33-66 Columns</button>
                                        <button class="button is-small add-column"
                                        v-on:click="changeColumns(6, key, area)">25-75 Columns</button>
                                    </div>
                                </div>
                                <p class="note">Note: changing the columns after writing text will remove the text that is written. Change your columns, then enter your content.</p>
                                <div class="row">
                                    <div class="col-md-1">
                                        <b-select size="is-small" v-model="area.type">
                                            <option  :value="type" :key="key2" v-for="(type, key2) in pageAreaTypes">{{ type }}</option>
                                        </b-select>
                                    </div>
                                    <div class="col-md-10">
                                        <div v-if="area.type === 'textarea'">
                                            <div class="row">
                                                <div :class="'col-md-' + column.width" v-for="(column, key3) in area.columns" :key="key3">
                                                    <vue-editor 
                                                        useCustomImageHandler 
                                                        @image-added="handleImageAdded"
                                                        v-model="column.content">
                                                    </vue-editor>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="area.type === 'widget'">
                                            <div class="select-a-widget" v-if="area.widget_type === null">
                                                <div class="select-a-widget-header">
                                                    Select a Widget
                                                </div>
                                                <div class="widget-options">
                                                    <a class="button"
                                                        v-on:click="area.widget_type = 'form'">Select a Form to Integrate</a>
                                                    <a class="button" 
                                                        v-on:click="area.widget_type = 'table'">Create a Table</a>
                                                    <a class="button" 
                                                        v-on:click="area.widget_type = 'tabs'">Create a Tabs</a>
                                                    <a class="button" 
                                                        v-on:click="area.widget_type = 'panels'">Create Panel Content</a>
                                                        <a class="button" 
                                                        v-on:click="area.widget_type = 'social'">Create Social Follows</a>
                                                    <a class="button" 
                                                        v-on:click="area.widget_type = 'custom_html'">Custom HTML</a>
                                                </div>
                                            </div>
                                            <div v-if="area.widget_type !== null" style="padding-bottom: 20px;">
                                                <a class="button" v-on:click="area.widget_type = null">&lt; Back to Widget Selection</a>
                                            </div>
                                            <div class="selected-widget" v-if="area.widget_type === 'panels'">
                                                <div :key="key" v-for="(panel, key) in area.panels">
                                                    <b-field label="Header">
                                                        <b-input v-model="panel.header"></b-input>
                                                    </b-field>
                                                    <b-field label="Content">
                                                        
                                                        <vue-editor 
                                                            useCustomImageHandler @image-added="handleImageAdded"
                                                            v-model="panel.content">
                                                        </vue-editor>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="selected-widget" v-if="area.widget_type === 'social'">
                                                Social buttons will appear on frontend.
                                            </div>
                                            <div class="selected-widget" v-if="area.widget_type === 'custom_html'">
                                                <b-field label="Paste Your Code">
                                                    <b-input type="textarea" v-model="area.custom_html"></b-input>
                                                </b-field>
                                            </div>
                                            <div class="selected-widget" v-if="area.widget_type === 'form'">
                                                <b-field label="Select Form">
                                                    <b-select v-model="area.form_id" placeholder="Select the Form You Want to Integrate">
                                                        <option :value="form.slug" v-for="(form,key) in forms" :key="key">
                                                            {{ form.name }} | {{ form.fr_name }}
                                                        </option>
                                                    </b-select>
                                                </b-field>
                                            </div>
                                            <div class="selected-widget" v-if="area.widget_type === 'table'">
                                                <div v-if="area.table === null"> 
                                                    <div class="table-flex" :key="key" v-for="(row, key) in tableToBeInserted" >
                                                        <div class="flex-cell" :key="key2" v-for="(column, key2) in row.row">
                                                            <b-input placeholder="cell" v-model="column.name"></b-input>
                                                        </div>
                                                        <div class="flex-cell" style="max-width: 50px;">
                                                            
                                                        </div>
                                                    </div>
                                                    <a class="button" v-on:click="addRow()">
                                                        Add Row to Table
                                                    </a>
                                                    <a class="button" v-on:click="addCol()">
                                                        Add Column to Table
                                                    </a>
                                                    <a class="button"  v-on:click="insertTable(area, key)">
                                                        When Finished: Insert Table
                                                    </a>
                                                </div>
                                                <div v-else>
                                                    <pre>
                                                        {{ area.table }}
                                                    </pre>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="area.type === 'table'">
                                            
                                            <div class="d-flex flex-row" v-for="(row, key) of area.table" :key="key">
                                                <div :class="`flex-column`" v-for="(column, key2) of row.row" :key="key2">
                                                    <b-input v-model="column.name"></b-input>
                                                </div>
                                                <div class="flex-column">
                                                    <a class="button" v-on:click="addColToRow(area.table, key)">+ Row Below</a>
                                                    <a class="button is-danger" v-on:click="removeColToRow(area.table, key)">Erase</a>
                                                </div>
                                            </div>
                                            <br>
                                            <a class="button" v-on:click="addRow(area.table)">Add Row</a>
                                            <a class="button" v-on:click="addCol(area.table)">Add Column</a>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="padding-left: 10px;">
                                        <a v-on:click="removeRow(key)" class="button is-small" v-if="key !== 0"><i class="fas fa-trash"></i>&nbsp;&nbsp;&nbsp;Remove Row</a>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <a v-on:click="addPageRow()" class="button is-info" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Add a Table Row</a>
                            
                            <a v-on:click="addPageRow(null, 'widget', 'form')" class="button" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Add a Form</a>

                            <a v-on:click="addPageRow(null, 'widget', 'table')" class="button" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Add a Table</a>

                            <a v-on:click="addPageRow(null, 'widget', 'custom_html')" class="button" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Add Custom HTML</a>

                            <a v-on:click="addPageRow(null, 'widget', 'panels')" class="button" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Add a Panel</a>
                        </b-tab-item>
                        <b-tab-item label="French Content" style='text-align: center;'>
                            <div class="page-create-content-area" :key="key" v-for="(area, key) in contentAreas">
                                <div class="row" style="padding-bottom: 10px;">
                                    <div class="col-md-12">
                                        <button class="button is-small add-column" v-on:click="changeColumns(1, key, area)">One Column</button>
                                        <button class="button is-small add-column" v-on:click="changeColumns(2, key, area)">Two Columns</button>
                                        <button class="button is-small add-column" v-on:click="changeColumns(3, key, area)">Three Columns</button>
                                        <button class="button is-small add-column" v-on:click="changeColumns(4, key, area)">Four Columns</button>
                                        <button class="button is-small add-column" v-on:click="changeColumns(5, key, area)">33-66 Columns</button>
                                        <button class="button is-small add-column" v-on:click="changeColumns(6, key, area)">25-75 Columns</button>
                                    </div>
                                </div>
                                <p class="note">Note: changing the columns after writing text will remove the text that is written. Change your columns, then enter your content.</p>
                                <div class="row">
                                    <div class="col-md-1">
                                        <b-select size="is-small" v-model="area.type">
                                            <option :value="type" :key="key2" v-for="(type, key2) in pageAreaTypes">{{ type }}</option>
                                        </b-select>
                                    </div>
                                    <div class="col-md-10">
                                        <div v-if="area.type === 'textarea'">
                                            <div class="row">
                                                <div :class="'col-md-' + column.width" v-for="(column, key3) in area.columns" :key="key3">
                                                    <vue-editor 
                                                        useCustomImageHandler 
                                                        @image-added="handleImageAdded"
                                                        v-model="column.content_fr">
                                                    </vue-editor>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="area.type === 'widget'">
                                            <div class="select-a-widget" v-if="area.widget_type === null">
                                                <div class="select-a-widget-header">
                                                    Select a Widget
                                                </div>
                                                <div class="widget-options">
                                                    <a class="button"
                                                        v-on:click="area.widget_type = 'form'">Select a Form to Integrate</a>
                                                    <a class="button" 
                                                        v-on:click="area.widget_type = 'table'">Create a Table</a>
                                                    <a class="button" 
                                                        v-on:click="area.widget_type = 'tabs'">Create a Tabs</a>
                                                    <a class="button" 
                                                        v-on:click="area.widget_type = 'panels'">Create Panel Content</a>
                                                        <a class="button" 
                                                        v-on:click="area.widget_type = 'social'">Create Social Follows</a>
                                                    <a class="button" 
                                                        v-on:click="area.widget_type = 'custom_html'">Custom HTML</a>
                                                </div>
                                            </div>
                                            <div v-if="area.widget_type !== null" style="padding-bottom: 20px;">
                                                <a class="button" v-on:click="area.widget_type = null">&lt; Back to Widget Selection</a>
                                            </div>
                                            <div class="selected-widget" v-if="area.widget_type === 'panels'">
                                                <div :key="key" v-for="(panel, key) in area.panels">
                                                    <b-field label="Header">
                                                        <b-input v-model="panel.header_fr"></b-input>
                                                    </b-field>
                                                    <b-field label="Content">
                                                        <vue-editor 
                                                            useCustomImageHandler 
                                                            @image-added="handleImageAdded"
                                                            v-model="panel.content_fr">
                                                        </vue-editor>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="selected-widget" v-if="area.widget_type === 'social'">
                                                Social buttons will appear on frontend.
                                            </div>
                                            <div class="selected-widget" v-if="area.widget_type === 'custom_html'">
                                                <b-field label="Paste Your Code">
                                                    <b-input type="textarea" v-model="area.custom_html_fr"></b-input>
                                                </b-field>
                                            </div>
                                            <div class="selected-widget" v-if="area.widget_type === 'form'">
                                                <b-field label="Select Form">
                                                    <b-select v-model="area.form_id" placeholder="Select the Form You Want to Integrate">
                                                        <option :value="form.slug" v-for="(form,key) in forms" :key="key">
                                                            {{ form.name }} | {{ form.fr_name }}
                                                        </option>
                                                    </b-select>
                                                </b-field>
                                            </div>
                                            <div class="selected-widget" v-if="area.widget_type === 'table'">
                                                <div v-if="area.table === null"> 
                                                    <div class="table-flex" :key="key" v-for="(row, key) in tableToBeInserted" >
                                                        <div class="flex-cell" :key="key2" v-for="(column, key2) in row.row">
                                                            <b-input placeholder="cell" v-model="column.name_fr"></b-input>
                                                        </div>
                                                        <div class="flex-cell" style="max-width: 50px;">
                                                            
                                                        </div>
                                                    </div>
                                                    <a class="button" v-on:click="addRow()">
                                                        Add Row to Table
                                                    </a>
                                                    <a class="button" v-on:click="addCol()">
                                                        Add Column to Table
                                                    </a>
                                                    <a class="button"  v-on:click="insertTable(area, key)">
                                                        When Finished: Insert Table
                                                    </a>
                                                </div>
                                                <div v-else>
                                                    <pre>
                                                        {{ area.table }}
                                                    </pre>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="area.type === 'table'">
                                            
                                            <div class="d-flex flex-row" v-for="(row, key) of area.table" :key="key">
                                                <div :class="`flex-column`" v-for="(column, key2) of row.row" :key="key2">
                                                    <b-input v-model="column.name_fr"></b-input>
                                                </div>
                                                <div class="flex-column">
                                                    <a class="button" v-on:click="addColToRow(area.table, key)">+ Row Below</a>
                                                    <a class="button is-danger" v-on:click="removeColToRow(area.table, key)">Erase</a>
                                                </div>
                                            </div>
                                            <br>
                                            <a class="button" v-on:click="addRow(area.table)">Add Row</a>
                                            <a class="button" v-on:click="addCol(area.table)">Add Column</a>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="padding-left: 10px;">
                                        <a v-on:click="removeRow(key)" class="button is-small" v-if="key !== 0"><i class="fas fa-trash"></i>&nbsp;&nbsp;&nbsp;Remove Row</a>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <a v-on:click="addPageRow()" class="button is-info" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Add a Table Row</a>
                            
                            <a v-on:click="addPageRow(null, 'widget', 'form')" class="button" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Add a Form</a>

                            <a v-on:click="addPageRow(null, 'widget', 'table')" class="button" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Add a Table</a>

                            <a v-on:click="addPageRow(null, 'widget', 'custom_html')" class="button" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Add Custom HTML</a>

                            <a v-on:click="addPageRow(null, 'widget', 'panels')" class="button" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Add a Panel</a>
                        </b-tab-item>
                    </b-tabs>                               
                </div>
            </div>
        </section>
        
        <footer class="modal-card-foot">
            <button v-on:click="saveNewPage()" role="button" class="admin-button"><i class="fas fa-save"></i> Save New Page</button>
        </footer>
    </div>


        
</template>

<script>


// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { VueEditor, Quill } from "vue2-editor";

import PageContentBox from '../../frontend/PageContentBox.vue'

import axios from 'axios'
export default {
	data() {

		return {
            editPageSwitch: false,
            authUser: { first_name: null },
            content_width: false,
            previewMode: false,
            faqs: [],
            pageAreaTypes: ['textarea','widget','table'],
            newPage: {
                name: null,
                fr_name: null,
                slug: null,
                content: null,
                fr_content: null,  
                latest_news: false
            },
            contentAreas: [{
                type: 'textarea',
                widget_type: null,
                form_id: null,
                table: null,
                custom_html: null,
                panels: [
                    { 
                        header: null,
                        header_fr: null,
                        content: null,
                        content_fr: null,
                        switched: false
                    }
                ],
                columns: [
                    {
                        width: 12,
                        content: null,
                        fr_content: null
                    },
                ]
            }],
            forms: [],
            tabSelected: 0,
            defaultOpenedDetails: [1],
            tableToBeInserted: [{
                row: [
                    {
                        name: 'Test'
                    },
                    {
                        name: null
                    }
                ]
            }]
		};
	},
	components: {
        VueEditor,
        PageContentBox
    },
    watch: {
        editPage: function(content) {
            var vm = this
            if(content) {
                vm.contentAreas = content.contentAreas
                vm.newPage = content

                console.log('contentAreas', content.contentAreas)
            } else {
                vm.newPage = {
                    name: null,
                    slug: null,
                    content: null,
                    fr_content: null,  
                    latest_news: false
                }
                vm.contentAreas = [{
                    type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    table: null,
                    custom_html: null,
                    panels: [
                        { 
                            header: null,
                            header_fr: null,
                            content: null,
                            content_fr: null,
                            switched: false
                        }
                    ],
                    columns: [
                        {
                            width: 12,
                            content: null,
                            fr_content: null
                        },
                    ]
                }]
            }
        }
    },
	mounted: function() {
		// grab all the dashboard information now
        var vm = this
        console.log('vm.editPage', vm.editPage)
        if(vm.editPage) {
            vm.contentAreas = vm.editPage.contentAreas
            vm.newPage = vm.editPage
        }
        
        if(vm.latestNews === true) {
            vm.newPage.latest_news = true
        }
		
		axios.get('/auth/check').then(results => {  
			 
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_site_pages !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                axios.get('/api/superuser/pages').then(results => {
                    if(results.data.success === true) {
                        vm.pages = results.data.pages
                        
                        // format the content
                    }
                })

                axios.get('/api/superuser/faqs').then(results => {
                    if(results.data.success === true) {
                        vm.faqs = results.data.faqs
                    }
                })

                axios.get('/api/superuser/forms').then(results => {
                    if(results.data.success === true) {
                        vm.forms = results.data.forms
                    }
                })
            }
        })
    },
    props: ['editPage', 'latestNews'],
	computed: {
	
	},
	methods: {
        goBack: function(data){
            var r = confirm('Are you sure you want to do this? You will lose this work.')
            if(r === true) {
                this.pageSelectedForEditing = null
            }
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        saveNewPage: function() {
            var vm = this
            
            var page_data_necessary = vm.newPage
            console.log('page_data_necessary', page_data_necessary)
            delete page_data_necessary.contentAreas
            delete page_data_necessary.fr_content
            delete page_data_necessary.content
            delete page_data_necessary.created_at   
            delete page_data_necessary.updated_at
            delete page_data_necessary.nationals_results
            delete page_data_necessary.provincials_results
            console.log('page_data_necessary', page_data_necessary)
            if(vm.editPage === null) {
                axios.post('/api/superuser/save-new-page', {
                    page: page_data_necessary,
                    content: vm.contentAreas
                }).then(results => {
                    
                    if(results.data.success === true) {
                        vm.pages = results.data.pages
                        vm.$buefy.snackbar.open({
                            message: 'Saved page successfully',
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })
                        vm.editPage = null
                        vm.newPage = {
                            name: null,
                            fr_name: null,
                            slug: null,
                            content: null,
                            fr_content: null,  
                            latest_news: false
                        }
                        vm.contentAreas = [{
                            type: 'textarea',
                            widget_type: null,
                            form_id: null,
                            table: null,
                            custom_html: null,
                            panels: [
                                { 
                                    header: null,
                                    header_fr: null,
                                    content: null,
                                    content_fr: null,
                                    switched: false
                                }
                            ],
                            columns: [
                                {
                                    width: 12,
                                    content: null,
                                    fr_content: null
                                },
                            ]
                        }]
                        vm.$emit('page:created')
                    } else {
                        vm.$buefy.snackbar.open({
                            message: 'Something went wrong when saving this page',
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })
                    }
                })
            } else {
                axios.post('/api/superuser/update-page', {
                    page: page_data_necessary,
                    content: vm.contentAreas
                }).then(results => {
                    
                    if(results.data.success === true) {
                        vm.pages = results.data.pages
                        vm.$buefy.snackbar.open({
                            message: 'Saved page successfully',
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })
                        vm.$emit('page:created')
                        vm.editPage = null
                        vm.newPage = {
                            name: null,
                            fr_name: null,
                            slug: null,
                            content: null,
                            fr_content: null,  
                            latest_news: false
                        }
                        vm.contentAreas = [{
                            type: 'textarea',
                            widget_type: null,
                            form_id: null,
                            table: null,
                            custom_html: null,
                            panels: [
                                { 
                                    header: null,
                                    header_fr: null,
                                    content: null,
                                    content_fr: null,
                                    switched: false
                                }
                            ],
                            columns: [
                                {
                                    width: 12,
                                    content: null,
                                    fr_content: null
                                },
                            ]
                        }]
                        vm.createNewPageSwitch = false
                    } else {
                        vm.$buefy.snackbar.open({
                            message: 'Something went wrong when saving this page',
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })
                    }
                })
            }
        },
        
        emitClose: function() {
            this.$emit('close:modal')
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        addPageRow: function(area = null, type = 'textarea', widget_type = null) {
            var vm = this
            
            if(area) {
                area.push({
                    type: type,
                    widget_type: widget_type,
                    form_id: null,
                    table: null,
                    custom_html: null,
                    panels: [
                        { 
                            header: null,
                            header_fr: null,
                            content: null,
                            content_fr: null,
                            switched: false
                        }
                    ],
                    columns: [
                        {
                            width: 12,
                            content: null,
                            fr_content: null
                        },
                    ]
                })
            } else {
                vm.contentAreas.push({
                    type: type,
                    widget_type: widget_type,
                    form_id: null,
                    table: null,
                    custom_html: null,
                    panels: [
                        { 
                            header: null,
                            header_fr: null,
                            content: null,
                            content_fr: null,
                            switched: false
                        }
                    ],
                    columns: [
                        {
                            width: 12,
                            content: null,
                            fr_content: null
                        },
                    ]
                })
            }
        },
        removeRow: function(index) {
            this.contentAreas.splice(index, 1);
        },
        handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("logo", file);

            

            axios.post('/api/teams/upload-photo', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(results => {
                if(results.data.success === true) {
                    Editor.insertEmbed(cursorLocation, "image", results.data.data.logo_path);
                    resetUploader();
                    // console.log('upload_path', results.data.data.logo_path)
                }
            })
        },
        changeColumns: function(number, key, area, edit = false) {
            
            if(number === 1) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    table: null,
                    custom_html: null,
                    panels: [
                        { 
                            header: null,
                            header_fr: null,
                            content: null,
                            content_fr: null,
                            switched: false
                        }
                    ],
                    columns: [
                        {
                            width: 12,
                            content: null,
                            fr_content: null
                        },
                    ]
                }
            }
            if(number === 2) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    table: null,
                    custom_html: null,
                    panels: [
                        { 
                            header: null,
                            header_fr: null,
                            content: null,
                            content_fr: null,
                            switched: false
                        }
                    ],
                    columns: [
                        {
                            width: 6,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 6,
                            content: null,
                            fr_content: null
                        },
                    ]
                }
            }
            if(number === 3) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    table: null,
                    custom_html: null,
                    panels: [
                        { 
                            header: null,
                            header_fr: null,
                            content: null,
                            content_fr: null,
                            switched: false
                        }
                    ],
                    columns: [
                        {
                            width: 4,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 4,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 4,
                            content: null,
                            fr_content: null
                        },
                    ]
                }
            }

            if(number === 4) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    table: null,
                    custom_html: null,
                    panels: [
                        { 
                            header: null,
                            header_fr: null,
                            content: null,
                            content_fr: null,
                            switched: false
                        }
                    ],
                    columns: [
                        {
                            width: 3,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 3,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 3,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 3,
                            content: null,
                            fr_content: null
                        }
                    ]
                }
            }

            if(number === 6) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    table: null,
                    custom_html: null,
                    panels: [
                        { 
                            header: null,
                            header_fr: null,
                            content: null,
                            content_fr: null,
                            switched: false
                        }
                    ],
                    columns: [
                        {
                            width: 4,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 8,
                            content: null,
                            fr_content: null
                        },
                    ]
                }
            }

            if(number === 7) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    table: null,
                    custom_html: null,
                    panels: [
                        { 
                            header: null,
                            header_fr: null,
                            content: null,
                            content_fr: null,
                            switched: false
                        }
                    ],
                    columns: [
                        {
                            width: 3,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 9,
                            content: null,
                            fr_content: null
                        },
                    ]
                }
            }

            
            if(edit === false) {
                this.contentAreas.splice(key, 1, contentArea)
            } else {
                this.pageSelectedForEditing.content.splice(key,1, contentArea)
            }
        },
        
        addCol: function(table) {
            var vm = this
            var i = 0
            
            var columns = table[0].row.length
            
            for(const row of table) {
                
                row.row.push({
                    name: ''
                })
            }            
        },
        removeColToRow: function(table, row) {
       

            table.splice(row, 1)
        },
        addColToRow: function(table, row) {
            

            var generateRow = []

            var r = 0

            var columns = table[0].row.length

            for(r; r < columns; r++) {
                generateRow.push({
                    name: ''
                })
            }
            
            table.splice(row, 0, {row: generateRow }) 
            
        },
        addRow: function(table) {
            // find the number of columns:
            var columns = table[0].row.length
            
            var row = []

            var r = 0
            
            for(r; r < columns; r++) {
                row.push({
                    name: ''
                })
            }

            table.push({
                row
            })
        },
        insertTable: function(area, key) {
            this.contentAreas[key].table = this.tableToBeInserted
            this.tableToBeInserted = [{
                row: [
                    {
                        name: 'Test'
                    },
                    {
                        name: null
                    }
                ]
            }]
        }
	}
}
</script>

<style lang="scss">
    
</style>
