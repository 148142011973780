<template>
<span>

    <div :class="['sidebar', { darker: superuserSwitch }]">
   
        <div class="menu-area">
            <div class="main-menu-results">
                <div class="main-menu-result">
                    <a v-if="hasSuperuser" v-on:click="superUserSwitcher()">
                        Switch to 
                        <span v-if="superuserSwitch === false">Superuser</span>
                        <span v-if="superuserSwitch === true">Player Account</span>
                    </a>
                    <a v-on:click="selectLang(!en)">{{ en === true ? 'Switch to French' : 'Switch to English' }}</a>
                    <a href="/dashboard">{{ en === true ? 'Dashboard' : 'Tableau de Bord' }}</a>
                    
                </div>
            </div>
        </div>
        <span v-if="user">
            <span v-if="user.default_region_id !== 4">
                <div class="menu-area" v-if="superuserSwitch === false">
                    <div class="main-menu-tab" v-on:click="players_coaches = !players_coaches">
                        {{ en === true ? 'Players and Coaches' : 'Joueurs et Instructeurs' }}
                        <i :class="['fas', { 'fa-caret-up': players_coaches === true }, { 'fa-caret-down': players_coaches === false }]"></i>
                    </div>
                    <div class="main-menu-results" v-if="players_coaches">
                        <div class="main-menu-result">
                            <a :class="{ selected: params === 'my-teams' }" href="/dashboard/players/my-teams" id="v-step-0">
                                {{ en === true ? 'My Teams' : 'Mes Équipes' }}
                            </a>
                            <a :class="{ selected: params === 'events-registered-in' }" href="/dashboard/players/events-registered-in"  id="v-step-1">
                                {{ en === true ? 'My Leagues & Tournaments Rosters' : 'Mon Tournois & Ligues' }}  
                            </a>
                            <a :class="{ selected: params === 'tournament-calendar' }" href="/dashboard/players/tournament-calendar"  id="v-step-2">
                                {{ en === true ? 'Upcoming Tournaments' : 'Les Nouveau Tournois' }}  
                            </a>
                        </div>
                    </div>
                    <div class="additional-tools" v-if="players_coaches">
                        {{ en === true ? 'Additional Tools' : 'Outils Supplémentaires' }}
                    </div>
                    <div class="main-menu-results" v-if="players_coaches">
                        
                        <a class="tool" href="/rulebook" target="_blank">
                            {{ en === true ? 'SPN Rulebook' : "Livre de Règlement SPN" }}
                        </a>
                        <a class="tool" href="https://spncloud.egnyte.com/dl/lYJFupH710" target="_blank">
                            {{ en === true ? 'Printable 8.5x11 Scorepage' : "Printable 8.5x11 Scorepage" }}
                        </a>
                        <a class="tool" href="https://spncloud.egnyte.com/dl/GbFvUapMXp" target="_blank">
                            {{ en === true ? 'Printable 14x8.5 Scorepage' : "Printable 14x8.5 Scorepage" }}
                        </a>
                    </div>
                </div>
            </span>
        </span>
        <div class="menu-area" v-if="superuserSwitch === false">
            <div id="v-step-3" class="main-menu-tab" v-on:click="umpires_tab = !umpires_tab">
                {{ en === true ? 'Umpires' : "Officiels/Arbitres" }}
                <i :class="['fas', { 'fa-caret-up': umpires_tab === true }, { 'fa-caret-down': umpires_tab === false }]"></i>
            </div>
            <div v-if="umpires_tab">
                
                <div class="main-menu-results" v-if="umpire_permissions">
                    <a :class="{ selected: params === 'clinics' }" href="/dashboard/umpires/clinics" v-if="umpire_permissions.clinics">
                        {{ en === true ? 'Clinics' : "Clinique D'arbitre" }}
                    </a>
                    <a :class="{ selected: params === 'your-certificate' }" href="/dashboard/umpires/your-certificate">
                        
                        {{ en === true ? 'Certificate' : "Certificat D'arbitre" }}
                    </a>
                    <a :class="{ selected: params === 'exam' }" href="/dashboard/umpires/exam">
                        {{ en === true ? 'Exam' : "Examen D'arbitre" }}
                    </a>
                    <a :class="{ selected: params === 'cheat-sheet' }" href="/dashboard/umpires/cheat-sheet" v-if="umpire_permissions.cheat_sheet">
                        {{ en === true ? 'Umpire Cheat Sheet' : "Notes pour Règles Locales" }}
                    </a>
                </div>
                <div class="main-menu-results" v-if="!umpire_permissions">
                    <a href="/umpires/take-the-exam">
                        {{ en === true ? 'Take The Exam!' : "Veuillez Passer L'examen" }}
                    </a>
                </div>
                <div class="main-menu-results" v-if="!umpire_permissions">
                    <a href="https://slo-pitch.com/umpire-clinics">
                         Find a Clinic
                    </a>
                </div>
      
                <div class="additional-tools" v-if="umpire_permissions">
                    {{ en === true ? 'Additional Tools' : 'Outils Supplémentaires' }}
                </div>
                <div class="main-menu-results" v-if="umpire_permissions">
                    <a class="tool" v-if="en === true" href="https://sajkpk7k4mr.typeform.com/to/oT2uY7AO">
                        {{ en === true ? 'Injury Report Form' : "Rapport D'accident" }}
                    </a>
                    <a class="tool"  v-if="en === false" href="https://sajkpk7k4mr.typeform.com/to/uu8Bj7Tc">
                        {{ en === true ? 'Injury Report Form' : "Rapport D'accident" }}
                    </a>
                </div>
                <div class="main-menu-results" v-if="umpire_permissions">
                    <a v-if="en === true" class="tool" href="https://spncloud.egnyte.com/dl/o2jfDYPKBB">
                        {{ en === true ? 'Umpire Casebook' : "Carnet des Cas" }}
                    </a>
                    <a v-if="en === false" class="tool" href="https://spncloud.egnyte.com/dl/o2jfDYPKBB">
                        {{ en === true ? 'Umpire Casebook' : "Carnet des Cas" }}
                    </a>
                </div>
                
            </div>
        </div>
        <span v-if="user">
            <span v-if="user.default_region_id !== 4">
                <div class="menu-area" v-if="superuserSwitch === false">
                    <div class="main-menu-tab" v-on:click="convenors_tab = !convenors_tab"  id="v-step-4">
                        {{ en === true ? 'Leagues & Convenors' : "Les Ligues et Directeurs de Tournois" }}
                        <i :class="['fas', { 'fa-caret-up': convenors_tab === true }, { 'fa-caret-down': convenors_tab === false }]"></i>
                    </div>
                    <div v-if="convenors_tab">
                        
                        <div class="main-menu-results" v-if="convenor_permissions">
                            <a :class="{ selected: params === 'my-tournaments' }" href="/dashboard/convenors/my-tournaments"  v-if="convenor_permissions.my_tournaments">
                                {{ en === true ? 'My Tournaments' : "Mes tournois" }}
                            </a>
                            <a :class="{ selected: params === 'my-leagues' }" href="/dashboard/convenors/my-leagues" v-if="convenor_permissions.my_leagues">
                                {{ en === true ? 'My Leagues' : "Mes ligues" }}
                            </a>
                        </div>
                        <div class="additional-tools"  v-if="convenor_permissions">
                            {{ en === true ? 'Additional Tools' : "Outils" }}
                        </div>
                        <div class="main-menu-results">
                            <span  v-if="convenor_permissions">
                                <a target="_blank" class="tool" href="https://spncloud.egnyte.com/dl/qeoanfHX7q">
                                    {{ en === true ? 'Protest Form' : "Formulaire Pour Protêt" }}
                                </a>
                                <a target="_blank" class="tool" v-if="en === true" href="https://sajkpk7k4mr.typeform.com/to/oT2uY7AO">
                                    {{ en === true ? 'Injury Report Form' : "Rapport D'accident" }}
                                </a>
                                <a target="_blank" class="tool"  v-if="en === false" href="https://sajkpk7k4mr.typeform.com/to/uu8Bj7Tc">
                                    {{ en === true ? 'Injury Report Form' : "Rapport D'accident" }}
                                </a>
                                <a target="_blank" class="tool" :href="(en === true ? 'https://spncloud.egnyte.com/dl/sbzgWQ4NKE' : 'https://spncloud.egnyte.com/dl/krYbTRTjlO')">
                                    {{ en === true ? 'Insurance Form' : "Formulaire D'assurance" }}
                                </a>
                                
                                <a target="_blank" class="tool" href="/dashboard/convenors/poster-generator" v-if="convenor_permissions.additional_tools">
                                    
                                    {{ en === true ? 'Poster Generator' : "Générateur D'affiche D'événement" }}
                                </a>
                                <a target="_blank" class="tool" href="/dashboard/convenors/budget-generator" v-if="convenor_permissions.additional_tools">
                                    {{ en === true ? 'Budget Generator' : "Générateur de Budget D'événement" }}
                                </a>
                                <a target="_blank" class="tool" href="/dashboard/convenors/round-robin-generator" v-if="convenor_permissions.additional_tools">
                                    {{ en === true ? 'Round Robin Matchup Generator' : "Générateur de Tournoi a la Ronde" }}
                                </a>
                            </span>
                        </div>
                        <div class="main-menu-results" v-if="!convenor_permissions">
                            <a href="/dashboard/convenors/become-a-convenor">
                                Become a Convenor
                            </a>
                        </div>
                    </div>
                </div>
            </span>
        </span>
        
        <div class="menu-area" v-if="superuserSwitch === false">
            
            <div class="main-menu-tab" id="v-step-5">
                {{ en === true ? 'My Account' : "Mon Compte" }} <i class="fas fa-caret-down"></i>
            </div>
            <div class="main-menu-results">
                <a :class="{ selected: path === '/dashboard/account-information' }" href="https://playslopitch.com/" v-if="user && user.default_region_id === 4">
                    {{ en === true ? 'Head to PlaySlopitch.com' : "Head to PlaySlopitch.com" }}
                </a>
                <a :class="{ selected: path === '/dashboard/account-information' }" href="/dashboard/account-information">
                    {{ en === true ? 'Account Information' : "Contentu de mon Compte" }}
                </a>
                <a v-on:click="logout()">{{ en === true ? 'Logout' : "Quitter" }}</a>
            </div>
        </div>
        <div class="menu-area" v-if="superuserSwitch === false && user && user.default_region_id !== 4">
            <div class="main-menu-tab">
                {{ en === true ? 'Tour Our System' : "Visitez Notre Système" }}  <i class="fas fa-caret-down"></i>
            </div>
            <div class="main-menu-results">
                <a v-on:click="startTour()">
                    {{ en === true ? 'System Tour' : "Tour du Système" }}
                </a>
            </div>
        </div>
        <div class="superuser" v-if="hasSuperuser">
            <div class="menu-area" v-if="superuserSwitch === true">
                <div class="main-menu-tab">
                    Superuser Tools<i class="fas fa-caret-down"></i>
                </div>
                <div class="note tools">These tools are for YOU and YOU only. Do NOT share your account information with anyone.</div>
                <div class="main-menu-results">
                    <router-link :class="{ selected: path === '/superadmin/manage/forms' }" v-if="permissions.manage_forms" :to="`/superadmin/manage/forms`">
                        Manage Forms 
                    </router-link>
                    <!-- <a :class="{ selected: path === '/superadmin/manage/forms' }" v-if="permissions.manage_forms" href="/superadmin/manage/forms">
                        Manage Forms 
                    </a> -->
                    <router-link :class="{ selected: path === '/superadmin/manage/form-results' }" v-if="permissions.manage_form_results" :to="`/superadmin/manage/form-results`">
                        Manage Form Results 
                    </router-link>
                     <!-- <a :class="{ selected: path === '/superadmin/manage/form-results' }" v-if="permissions.manage_form_results" href="/superadmin/manage/form-results">
                        Manage Form Results 
                    </a> -->
                    <router-link :class="{ selected: path === '/superadmin/manage/convenors' }" v-if="permissions.manage_convenors" :to="`/superadmin/manage/convenors`">
                        Manage Convenors
                    </router-link>
                    <!-- <a :class="{ selected: path === '/superadmin/manage/convenors' }" v-if="permissions.manage_convenors" href="/superadmin/manage/convenors">
                        Manage Convenors
                    </a> -->

                    <router-link :class="{ selected: path === '/superadmin/manage/leagues' }" v-if="permissions.manage_leagues" :to="`/superadmin/manage/leagues`">
                        Manage Leagues 
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/tournaments' }" v-if="permissions.manage_tournaments" :to="`/superadmin/manage/tournaments`">
                        Manage Tournaments
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/memberships' }" v-if="permissions.manage_tournaments" :to="`/superadmin/manage/memberships`">
                        Manage Memberships
                    </router-link>

                    <router-link :class="{ selected: path === '/superadmin/manage/tournament-results' }" v-if="permissions.manage_tournaments" :to="`/superadmin/manage/tournament-results`">
                        Tournament Results
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/orders' }" v-if="permissions.manage_orders" :to="`/superadmin/manage/orders`">
                        Manage Orders
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/teams' }" v-if="permissions.manage_teams" :to="`/superadmin/manage/teams`">
                        Manage Teams
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/umpires/umpires' }" v-if="permissions.manage_umpires" :to="`/superadmin/manage/umpires/umpires`">
                        Manage Umpires
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/umpires/exam' }" v-if="permissions.manage_umpire_exams" :to="`/superadmin/manage/umpires/exam`">
                        Manage Umpire Exam
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/clinics' }" v-if="permissions.manage_clinics" :to="`/superadmin/manage/clinics`">
                        Manage Clinics
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/users' }" v-if="permissions.manage_players" :to="`/superadmin/manage/users`">
                        Manage Users/Players 
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/banned' }" v-if="permissions.manage_players" :to="`/superadmin/manage/banned`">
                        Manage Banned Users 
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/waivers' }" v-if="permissions.manage_waivers" :to="`/superadmin/manage/waivers`">
                        Manage Waivers
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/site-pages' }" v-if="permissions.manage_site_pages" :to="`/superadmin/manage/site-pages`">
                        Manage Site Pages 
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/permissions' }" v-if="permissions.manage_permissions" :to="`/superadmin/manage/permissions`">
                        Manage Permissions
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/region_settings' }" v-if="permissions.manage_region_rates" :to="`/superadmin/manage/region_settings`">
                        Manage Region Settings
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/region_rates' }" v-if="permissions.manage_region_rates" :to="`/superadmin/manage/region_rates`">
                        Manage Region Rates
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/communication-log' }" v-if="permissions.communication_log" :to="`/superadmin/manage/communication-log`">
                        Communication Log 
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/merge-users' }" v-if="permissions.merge_users" :to="`/superadmin/merge-users`">
                        Merge Users 
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/manage/does-not-match-province' }" v-if="permissions.merge_users" :to="`/superadmin/manage/does-not-match-province`">
                        View Region Does Not Match Province
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/view-waivers-per-region' }" v-if="permissions.merge_users" :to="`/superadmin/view-waivers-per-region`">
                        View Waivers Signed Per Region
                    </router-link>
                    <router-link :class="{ selected: path === '/superadmin/statistics-center' }" v-if="permissions.merge_users" :to="`/superadmin/statistics-center`">
                        Statistics Center
                    </router-link>
          
                    <!-- <a :class="{ selected: path === '/superadmin/manage/leagues' }" v-if="permissions.manage_leagues" href="/superadmin/manage/leagues">
                        Manage Leagues 
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/tournaments' }" v-if="permissions.manage_tournaments" href="/superadmin/manage/tournaments">
                        Manage Tournaments
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/memberships' }" v-if="permissions.manage_tournaments" href="/superadmin/manage/memberships">
                        Manage Memberships
                    </a>

                    <a :class="{ selected: path === '/superadmin/manage/tournament-results' }" v-if="permissions.manage_tournaments" href="/superadmin/manage/tournament-results">
                        Tournament Results
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/orders' }" v-if="permissions.manage_orders" href="/superadmin/manage/orders">
                        Manage Orders
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/teams' }" v-if="permissions.manage_teams" href="/superadmin/manage/teams">
                        Manage Teams
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/umpires/umpires' }" v-if="permissions.manage_umpires" href="/superadmin/manage/umpires/umpires">
                        Manage Umpires
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/umpires/exam' }" v-if="permissions.manage_umpire_exams" href="/superadmin/manage/umpires/exam">
                        Manage Umpire Exam
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/clinics' }" v-if="permissions.manage_clinics" href="/superadmin/manage/clinics">
                        Manage Clinics
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/users' }" v-if="permissions.manage_players" href="/superadmin/manage/users">
                        Manage Users/Players 
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/banned' }" v-if="permissions.manage_players" href="/superadmin/manage/banned">
                        Manage Banned Users 
                    </a>
                    <a  :class="{ selected: path === '/superadmin/manage/waivers' }" v-if="permissions.manage_waivers" href="/superadmin/manage/waivers">
                        Manage Waivers
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/site-pages' }" v-if="permissions.manage_site_pages" href="/superadmin/manage/site-pages">
                        Manage Site Pages 
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/permissions' }" v-if="permissions.manage_permissions" href="/superadmin/manage/permissions">
                        Manage Permissions
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/region_rates' }" v-if="permissions.manage_region_rates" href="/superadmin/manage/region_rates">
                        Manage Region Rates
                    </a>
                   
                    <a :class="{ selected: path === '/superadmin/manage/communication-log' }" v-if="permissions.communication_log" href="/superadmin/manage/communication-log">
                        Communication Log 
                    </a>
                    <a :class="{ selected: path === '/superadmin/merge-users' }" v-if="permissions.merge_users" href="/superadmin/merge-users">
                        Merge Users 
                    </a>
                    <a :class="{ selected: path === '/superadmin/manage/does-not-match-province' }" v-if="permissions.merge_users" href="/superadmin/manage/does-not-match-province">
                        View Region Does Not Match Province
                    </a>
                    <a :class="{ selected: path === '/superadmin/view-waivers-per-region' }" v-if="permissions.merge_users" href="/superadmin/view-waivers-per-region">
                        View Waivers Signed Per Region
                    </a>
                    <a :class="{ selected: path === '/superadmin/statistics-center' }" v-if="permissions.merge_users" href="/superadmin/statistics-center">
                        Statistics Center
                    </a> -->
                </div>
                <div class="note tools">These tools are for YOU and YOU only. Do NOT share your account information with anyone.</div>
                <div class="note tools">Regular players cannot see the superadmin bar at all.</div>
            </div>
        </div>
    </div>
    <v-tour name="myTour" :steps="steps"></v-tour>
</span>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
    data() {
        return {
            en: true,
            user_coordinates: {
                lat: null,
                long: null
            },
            menu: [],
            submenus: [],
            user: null,
            superuserSwitch: false,
            hasSuperuser: false,
            permissions: null,
            umpire_permissions: null,
            convenor_permissions: null,
            params: null,
            path: null,
            players_coaches: true,
            umpires_tab: false,
            convenors_tab: false,
            steps: [
                {
                    target: '#v-step-0',
                    header: {
                        title: 'My Teams',
                    },
                    content: `This is where you can find all your Player Pool teams. These are different from League/Tournament Rosters. This is essentially where you can keep your longer team lists that you call on for leagues and tournaments.`
                },
                {
                    target: '#v-step-1',
                    header: {
                        title: 'League & Tournament Rosters',
                    },
                    content: `This is where you can find all your League and Tournament-specific rosters. Always ensure <u>all players</u> playing in an event are on a specific roster.`
                },
                {
                    target: '#v-step-2',
                    header: {
                        title: 'Upcoming Tournaments',
                    },
                    content: `Here you can find all upcoming tournaments sanctioned by Slo-Pitch National.`
                },
                {
                    target: '#v-step-3',
                    header: {
                        title: 'Umpires',
                    },
                    content: `Here you can find all the tools you need to become a certified umpire, as well as attend clinics nearby.`
                },
                {
                    target: '#v-step-4',
                    header: {
                        title: 'Leagues & Convenors',
                    },
                    content: `Here you can find all the tools you need to become a convenor, as well as manage your leagues and tournaments.`
                },
                {
                    target: '#v-step-5',
                    header: {
                        title: 'My Account',
                    },
                    content: `Here you can find all your account information, as well as logout.`
                },
            ]
        };
    },
    components: {
        
    },
    computed: {
        
    },
    watch: {
        
    },
    mounted: function() {
        var vm = this

        const start = 9 * 60;
        const end = 17 * 60;
        const date = new Date(); 
        const now = date.getHours() * 60 + date.getMinutes();
        const day_of_week = date.getDay()

        if(start <= now && now <= end) {
            // console.log('Chat is hidden.')
        } else {
            $('#front-chat-container').hide()    
        }
        // if(day_of_week === 5) {
        //     $('#front-chat-container').hide()    
        // }
        if(day_of_week === 6) {
            $('#front-chat-container').hide()    
        }
        if(day_of_week === 0) {
            $('#front-chat-container').hide()    
        }
        
        if(sessionStorage.getItem('lang')) {
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

        this.params = this.$route.params.page
        this.path = this.$route.path
        axios.get('/auth/check').then(results => {
            if(results.data.type === "success") {
                vm.user = results.data.user
                
                if(results.data.umpire_permissions) {
                    vm.umpire_permissions = results.data.umpire_permissions
                }

                if(results.data.convenor_permissions) {
                    vm.convenor_permissions = results.data.convenor_permissions
                }

                if(results.data.user_permissions) {
                    if(results.data.user_permissions.superadmin_access === 1) {
                        vm.hasSuperuser = true
                        vm.permissions = results.data.user_permissions

                        if(vm.$route.fullPath.search('superadmin') !== -1) {
                            vm.superuserSwitch = true
                        }
                    } else {
                        vm.hasSuperuser = false    
                    }
                } else {
                    vm.hasSuperuser = false
                }
            }
        })


        axios.get('/api/navigation/primary').then(results => {
            if(results.data) {
                vm.menu = results.data.main_menu
                vm.submenus = results.data.submenus
            }
        }).catch(err => {
            // console.log(err)
        })
    },
    methods: {

        selectMenu(item) {
            var vm = this
            
            if(item.has_submenu) {
                var filter = _.filter(vm.submenus, function(sub_item) {
                    return sub_item.submenu_id === item.slug
                })

                vm.submenu_filtered = filter
            }
            if(item.href) {
                // redirect
                // router.push({ path: '/content/' + item.href })
            }
        },
        selectSubMenu(item) {
            var vm = this
        
            this.$router.push({ path: '/content/' + item.href }).catch(err => {})   
        },
        superUserSwitcher: function() {
            var vm = this

            vm.superuserSwitch = !vm.superuserSwitch
            
            if(vm.superuserSwitch) {
                this.$buefy.toast.open({
                    message: 'Switched to superuser mode',
                    type: 'is-success', // is-danger
                    duration: 1000,
                    queue: false
                })
            } else {
                this.$buefy.toast.open({
                    message: 'Switched to player mode',
                    type: 'is-danger', // is-danger
                    duration: 1000,
                    queue: false
                })
            }
        },
        async logout () {
            try {
                localStorage.removeItem('auth')
                axios.get('/auth/logout').then(results => {
                    if(results.data.success === true) {
                        this.$router.push({ path: '/' }).catch(err => {})  
                    }
                })
            } catch(err) {

            }
            
        },
        selectLang: function(lang) {
            this.en = lang

            sessionStorage.setItem('lang', lang)

            this.$emit('update-lang', lang)
        },
        startTour: function() {
            this.$tours['myTour'].start()
        }
    }
}
</script>

<style lang="scss">
    @import '../../../styles/Dashboard.scss';
</style>
