<template>
<div>
    <div class="section-spacing" v-for="(info, key) in content" :key="key">
        <div class="textarea-content-block" v-if="info.type === 'textarea'">
            <div class="row">

                <div :class="'col-md-' + column.width" :key="column.id" v-for="column in info.columns">
                    <div class="padding" v-html="(en === true ? column.content : column.content_fr)">

                    </div>
                </div>
            </div>
        </div>
        <div class="widget-box" v-if="info.type === 'widget'">
            <div v-if="info.widget_type === 'form'">
                <Form :form-id="info.form_id"></Form>
            </div>
            <div v-if="info.widget_type === 'table'">
                <b-table
                    paginated
                    per-page="20"
                    :columns="createProperTableFunction(info.table).columns"
                    :data="createProperTableFunction(info.table).data">							
                </b-table>
            </div>
            <div v-if="info.widget_type === 'custom_html'" v-html="info.custom_html">
            
            </div>
            <div v-if="info.widget_type === 'panels'" >
                <Accordion>
                    <template v-slot:header>
                        {{ info.panels[0].header }}
                    </template>
                    <template v-slot:content>
                        <div v-html="info.panels[0].content"></div>
                    </template>
                </Accordion>
                <!-- <div class="panel">
                    
                    <div class="panel-heading" v-on:click="openPanel(info.panels[0])"> <i class="fas fa-caret-down" style="float: right;"></i></div>
                    <div class="panel-content" v-if="info.panels[0].switched === 'true'" ></div>
                </div> -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
			en: true,
            page_url: null,
            page: null
		};
	},
	components: {

    },
    props: ['content'],
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

        console.log("content inside", this.content)

	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
	},
	methods: {

	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
